import React from "react"
import { Link } from "gatsby"

import Layout from "../layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  return (
    <Layout pageId="page-not-found" theme="light">
      <SEO title="Page Not Found" />
      <section className="section--hero h-screen overflow-hidden">
        <div className="container mx-auto py-16 md:py-32 px-8 flex flex-col items-center justify-end max-h-screen h-full text-black">
          <div className="ff-secondary leading-snug">
            <h1 className="fs-30 fs-md-32 fs-lg-40 fs-xl-42 text-center">
              The page you were looking for <span className="text-primary">Doesn't Exist</span>
            </h1>
          </div>
          <div className="mt-8 fs-16 font-normal text-center">
            <Link className="btn btn-primary btn-shadowed py-2 px-12 fs-18 fs-md-21 font-extrabold inline-block text-white hover:text-white" to="/">Back to Home</Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
